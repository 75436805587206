<template>
  <v-card class="customers">
    <v-card-title>
        <h2>{{ title }}</h2>
        <v-spacer />
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
    </v-card-title>
    <v-data-table
    @update:page="goTo"  
    :headers="customerHeaders"
    :items="customers"
    class="data-table elevation-1"
    :loading="loading"
    loading-text="Loading Customers..."
    :items-per-page="50"
    :footer-props="{
    'items-per-page-options': [10, 25, 50, 100]
    }"
    :search="search"
    :no-data-text="noCustomers"
    @click:row="routeTo"
    height="70vh"
    fixed-header
  >
    <template
        v-slot:item.prospect="{ item }"
    >
        {{ item.prospect ? 'Yes' : 'No' }}
    </template>
  </v-data-table>
  <!-- <AddCustomer /> -->
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import AddCustomer from "@/components/AddCustomer"
export default {
  name: 'Customers',
  data() {
      return {
          search: "",
          title: "Customers",
          loading: false,
          dataCheck: false,
          options: {},
          customerHeaders: [
            {
                text: "Farm Name",
                value: "name"
            },
            {
                text: "Email",
                value: "emailaddress1"
            },
            {
                text: "Phone",
                value: "telephone1"
            },
            {
                text: "Address",
                value: "address1_line1"
            },
            {
                text: "City",
                value: "address1_city"
            },
            {
                text: "State",
                value: "address1_stateorprovince"
            },
            {
                text: "Zip",
                value: "address1_postalcode"
            },
            {
                text: "Dealer",
                value: "pfs_dealerid.pfs_name"
            },
            {
                text: "Prospect",
                value: "prospect"
            }
        ]
      }
  },
  async created() {
    if (this.$route.name === "DealerCustomers" && this.token) {
        this.$store.commit("customer/emptyCustomers")
        await this.getCustomers("dealer", this.$route.params.id)
    }
    else if (!this.customers.length && this.token) {
        await this.getCustomers()
    }
  },
  components: {
    AddCustomer
  },
  watch: {
    async token(val) {
        if (this.$route.name === "DealerCustomers") {
            this.$store.commit("customer/emptyCustomers")
            await this.getCustomers("dealer", this.$route.params.id)
        }
        else {
            await this.getCustomers()
        }
    }
  },
  computed: {
    ...mapState("customer", ["customers"]),
    ...mapState("user", ["token", "user"]),
    ...mapGetters("user", ["isRep", "isAdmin"]),
    noCustomers() {
        if (this.dataCheck) {
            return "No Customers Found"
        }
        else {
            return "Loading Customers..."
        }
    }
  },
  methods: {
      routeTo(item) {
        this.$router.push(`/customers/${item.accountid}`)
      },
      async getCustomers(method = "getCRMCustomers", id = null) {
            this.loading = true
            if (method === "dealer") {
                method = "getDealerCustomers"
            }
            else if (this.isRep) {
                method = "getDealerCustomers"
                id = this.user.dealerId
            }
            await this.$store.dispatch(`customer/${method}`, id)
            // if (this.customers.length) {
            //     this.title = this.customers[0].pfs_account_pfs_dealer_DealerAccount[0].pfs_name
            // }
            if (this.$route.name === "DealerCustomers") {
                this.title = `Customers - ${this.customers[0].pfs_dealerid.pfs_name}`
            }
            this.dataCheck = true
            this.loading = false
      },
    async addCustomer() {
        try {
            await this.$store.dispatch("customer/createCustomer", {
                first: "Sean",
                last: "Frederick",
                email: "sean.frederick@netcenter.net"
            })
        } catch (error) {
            console.log(error)
        }
    },
    goTo () {
        this.$vuetify.goTo(
            '.data-table tbody tr',
            {
            offset: 50,
                container: '.v-data-table__wrapper'
            }
        )
    }
  },
  beforeRouteLeave(to, from, next) {
    if (from.name === "DealerCustomers") {
        this.$store.commit("customer/emptyCustomers")
    }
    next()
  }
}
</script>

<style lang="scss" scoped>
    .customers {
        ::v-deep  tr {
            cursor: pointer;
        }
    }
</style>