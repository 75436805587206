<template>
    <v-form>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="customer.first"
            label="First name"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="customer.last"
            label="Last name"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="customer.email"
            label="E-mail"
            required
          ></v-text-field>
        </v-col>
          <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="customer.city"
            label="City"
            required
          ></v-text-field>
          </v-col>
          <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="customer.state"
            label="State"
            required
          ></v-text-field>
          </v-col>
          <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="customer.zip"
            label="Zip"
            required
          ></v-text-field>
          </v-col>
          <v-btn @click="addCustomer">Add</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
    name: "AddCustomer",
    data() {
        return {
            customer: {
                first: "",
                last: "",
                email: "",
                city: "",
                state: "",
                zip: ""
            }
        }
    },
    methods: {
        async addCustomer() {
            this.formatCustomer()
            try {
                await this.$store.dispatch("customer/createCustomer", this.customer)
                this.customer = {}
            } catch (error) {
                console.log(error)
            }
      },
      formatCustomer() {
        this.customer.first = this.capitalize(this.customer.first)
        this.customer.last = this.capitalize(this.customer.last)
        this.customer.city = this.capitalize(this.customer.city)
      },
      capitalize(item){
        return item.slice(0,1).toUpperCase() + item.slice(1)
      }
    }
}
</script>

<style lang="scss" scoped>

</style>